import styled from '@emotion/styled';
import { FC } from 'react';
import { IconNavigationSearch24 } from 'components/base/Icon';
import Link from 'components/base/Link';
import Header from 'components/common/Header';
import CartButton from 'components/toolbox/croquis/NavigationBar/CartButton';
import { useCartCount } from 'queries/cart';
import { black, white } from 'styles';
import { typography } from 'styles/font';

interface Props {
  title: string;
  search_path: string;
}

/**
 * 전용관 헤더
 * @component
 */
const SpecialCategoryHeader: FC<Props> = ({ title, search_path }) => {
  const { cart_count } = useCartCount();

  return (
    <Header
      left_icons={['back']}
      title={title}
      right_icons={
        <SC.Right>
          <Link href={search_path}>
            <SC.Search />
          </Link>
          <Link>
            <CartButton href='/cart' count={cart_count} />
          </Link>
        </SC.Right>
      }
    />
  );
};

export default SpecialCategoryHeader;

const SC = {
  AppHeader: styled.div`
    z-index: 2;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    background-color: ${white};
  `,
  Back: styled.button`
    height: 44px;
    align-items: center;
    display: flex;
    width: 66px;
  `,
  Title: styled.p`
    ${typography.title4}
    color:${black};
    text-align: center;
    display: flex;
    height: 44px;
    justify-content: center;
    align-items: center;
    line-height: 19px;
  `,
  Search: styled(IconNavigationSearch24)`
    margin-right: 16px;
    color: ${black};
    width: 24px;
    height: 24px;
  `,
  Right: styled.div`
    display: flex;
  `,
};
